import loadable from '@loadable/component'
import { Navigate, useRoutes } from 'react-router-dom'
import UserStore from 'src/mobx/user'

import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import ProtectedRoute from './layouts/ProtectedRoute'
import NotFound from './pages/404'
import Workouts from './pages/dashboard/workouts'
import {
  PATH_ACTIVATE,
  PATH_CV,
  PATH_DASHBOARD,
  PATH_DIARY,
  PATH_DICTIONARY,
  PATH_LOGIN,
  PATH_NOTFOUND,
  PATH_REGISTER,
  PATH_RESTORE_PASSWORD,
  PATH_TODO,
  PATH_WORKOUT,
  ROUTE_DICTIONARY,
  ROUTE_I_MEMORY,
  ROUTE_I_REMEMBER,
  ROUTE_LETTER_HAMMER,
  ROUTE_PROFILE,
  ROUTE_PUBLIC_DICTIONARY,
  ROUTE_SETTINGS,
} from './resources/routes'

const DashboardLayout = loadable(() => import('./layouts/dashboard'))
const Dictionary = loadable(() => import('./pages/dashboard/Dictionary'))
const LetterHammer = loadable(
  () => import('src/pages/dashboard/workouts/LetterHammer'),
)
const IMemory = loadable(() => import('./pages/dashboard/workouts/iMemory'))
const IRemember = loadable(() => import('./pages/dashboard/workouts/iRemember'))
const CV = loadable(() => import('./pages/dashboard/CV'))
const Diary = loadable(() => import('./pages/dashboard/diary'))
const Login = loadable(() => import('./pages/Login'))
const Todo = loadable(() => import('./pages/dashboard/Todo'))
const Register = loadable(() => import('./pages/Register'))
const Activate = loadable(() => import('./pages/Activate'))
const PublicDictionary = loadable(() => import('./pages/PublicDictionary'))
const FinishPasswordRestoration = loadable(
  () => import('./pages/FinishPasswordRestoration'),
)
const StartPasswordRestoration = loadable(
  () => import('./pages/StartPasswordRestoration'),
)
const Profile = loadable(() => import('./pages/user/Profile'))
const Settings = loadable(() => import('./pages/user/Settings'))

export default function Router() {
  return useRoutes([
    {
      path: PATH_DASHBOARD,
      element: (
        <ProtectedRoute isAllowed={!!UserStore.user}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: PATH_DICTIONARY, element: <Dictionary /> },
        { path: PATH_WORKOUT, element: <Workouts /> },
        { path: ROUTE_I_REMEMBER, element: <IRemember /> },
        { path: ROUTE_I_MEMORY, element: <IMemory /> },
        { path: ROUTE_LETTER_HAMMER, element: <LetterHammer /> },
        { path: PATH_TODO, element: <Todo /> },
        { path: PATH_CV, element: <CV /> },
        { path: PATH_DIARY, element: <Diary /> },
        { path: ROUTE_PROFILE, element: <Profile /> },
        { path: ROUTE_SETTINGS, element: <Settings /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to={ROUTE_DICTIONARY} /> },
        {
          path: PATH_LOGIN,
          element: <Login />,
        },
        { path: PATH_REGISTER, element: <Register /> },
        {
          path: `${PATH_ACTIVATE}/`,
          element: <Activate />,
          children: [{ path: ':link', element: <Activate /> }],
        },
        {
          path: PATH_RESTORE_PASSWORD,
          element: <StartPasswordRestoration />,
        },
        {
          path: `${PATH_RESTORE_PASSWORD}/:link`,
          element: <FinishPasswordRestoration />,
        },
        { path: PATH_NOTFOUND, element: <NotFound /> },
        { path: '*', element: <Navigate to={PATH_NOTFOUND} /> },
      ],
    },
    {
      path: `${ROUTE_PUBLIC_DICTIONARY}/:dictionaryId`,
      element: <PublicDictionary />,
    },
    { path: '*', element: <Navigate to={PATH_NOTFOUND} replace /> },
  ])
}
